export const validateRules = {
  data() {
    var validateProjectDepartment = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属项目部'));
      }
      callback();
    };
    var validateRiskPoint = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入风险点'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateLocation = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入位置'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateAccidentTypeIds = (rule, value, callback) => {
      if (value.length===0) {
        return callback(new Error('请输入主要事故类型'));
      }
      callback();
    };
    var validateRiskLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择风险级别'));
      }
      callback();
    };
    var validateControlMeasures = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入主要管控措施'));
      } else if (value.length > 200) {
        return callback(new Error('最大字符为200'));
      }
      callback();
    };
    var validateResponsibleUnit = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入责任单位'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateResponsible = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入责任人'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    return {
      rules: {
        projectDepartment: [{ validator: validateProjectDepartment, trigger: 'change' }],
        riskPoint: [{ validator: validateRiskPoint }],
        location: [{ validator: validateLocation }],
        accidentTypeIds: [{ validator: validateAccidentTypeIds }],
        riskLevelId: [{ validator: validateRiskLevelId, trigger: 'change' }],
        controlMeasures: [{ validator: validateControlMeasures }],
        responsibleUnit: [{ validator: validateResponsibleUnit }],
        responsible: [{ validator: validateResponsible }]
      }
    };
  }
};
