<template>
  <div class="echart-display">
    <!-- 搜素部分 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="显示层级" v-if="levelDoopDownList.length>1">
          <el-select
            v-model="queryForm.orgId"
            placeholder="请选择显示层级"
            @change="changeOrgIdList"
          >
            <el-option
              v-for="item in levelDoopDownList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select
            style="width:320px"
            v-model="queryForm.ids"
            placeholder="请选择项目部"
            multiple
            collapse-tags
            @change="changeProjectList"
          >
            <el-option v-for="item in projectList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select
            v-model="queryForm.accidentTypeIds"
            placeholder="请选择主要事故类型"
            collapse-tags
            multiple
            @change="changeAccidentList"
          >
            <el-option v-for="item in accidentTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="queryForm.riskLevelIds"
            placeholder="请选择风险级别"
            multiple
            collapse-tags
            @change="changeRiskLevelList"
          >
            <el-option v-for="item in riskLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { selectList } from '../selectList.js'; //这里才用混入，下拉匹配项的数据都有了，父组件和子组件弹窗都要用到
import { getRickPointPhotoData } from 'api/general-user/prevention-control/risk-control';
import { getDataRang,getDataRangByNature } from 'api/common';
import echartsLine from 'components/EchartsLine';
export default {
  mixins: [selectList],
  components: {
    echartsLine
  },
  data() {
    return {
      queryForm: {
        ids: [],
        orgId:1,
        riskLevelIds: [],
        accidentTypeIds: []
      },
      xData: [], //图表横坐标
      dataList: [], //图表数据
       //层级下拉列表
      levelDoopDownList:[],
      //项目部下拉列表
      projectList:[],
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllAccidentFlag: false, //主要事故类型(是否全选)
      isCheckedAllRiskLevelFlag: false //风险级别（是否全选）
    };
  },
  methods: {
    changeOrgIdList(){
      this.getDataRangByNature();
    },
    changeProjectList(id) {
      //选择属项目部
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.orgId);
          });
          this.queryForm.ids = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.ids = id;
        }
      } else {
        this.isCheckedAllProjectFlag = false;
        this.queryForm.ids = id.filter(item => item !== '');
      }
      this.getRickPointPhotoData();
    },
    changeAccidentList(id) {
      //选择主要事故类型
      this.queryForm.riskLevelIds = [];
      if (!this.isCheckedAllAccidentFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.accidentTypeList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.accidentTypeIds = arr;
          this.isCheckedAllAccidentFlag = true;
        } else {
          this.queryForm.accidentTypeIds = id;
        }
      } else {
        this.isCheckedAllAccidentFlag = false;
        this.queryForm.accidentTypeIds = id.filter(item => item !== '');
      }
      this.getRickPointPhotoData();
    },
    changeRiskLevelList(id) {
      //选择风险级别
      this.queryForm.accidentTypeIds = [];
      if (!this.isCheckedAllRiskLevelFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.riskLevelList.map(item => {
            arr.push(item.id);
          });
          this.queryForm.riskLevelIds = arr;
          this.isCheckedAllRiskLevelFlag = true;
        } else {
          this.queryForm.riskLevelIds = id;
        }
      } else {
        this.isCheckedAllRiskLevelFlag = false;
        this.queryForm.riskLevelIds = id.filter(item => item !== '');
      }
      this.getRickPointPhotoData();
    },
    getRickPointPhotoData() {
      //项目部危险源数量总数（默认）简单柱状图
      let params = cloneDeep(this.queryForm);
      if (params.ids.includes('')) {
        //判断数组中是否有空字符串
        params.ids = params.ids.filter(item => item !== '');; //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.accidentTypeIds.includes('')) {
        params.accidentTypeIds = params.accidentTypeIds.filter(item => item !== '');
      }
      if (params.riskLevelIds.includes('')) {
        params.riskLevelIds = params.riskLevelIds.filter(item => item !== '');
      }
      getRickPointPhotoData(params).then(res => {
        if (res.code === 200) {
          const { dangerSourcePhotoResults, name, allNum } = res.data;
          this.xData = name; //横坐标的值
          if (res.code === 200) {
            if (dangerSourcePhotoResults.length) {
              //只要选择了其他下拉选择，dangerSourcePhotoResults字段就有值
              dangerSourcePhotoResults.forEach(item => {
                item.type = 'bar';
                item.stack = 'bar';
                item.barWidth = 20;
                item.data = item.allNum;
              });
              this.dataList = dangerSourcePhotoResults;
            } else {
              //只选择了所属项目部
              this.dataList = [
                {
                  data: allNum,
                  type: 'bar',
                  barWidth: 20,
                  backgroundColor: '#1890FF',
                  name: '风险点数量'
                }
              ];
            }
          }
          this.$nextTick(() => {
            this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
          });
        }
      });
    },
     //获取层级
    getDataRang(){
      getDataRang().then(res=>{
        if(res.code===200){
          const {data}=res;
          if(data.length){
            this.levelDoopDownList.push(...data);
          }else{
            this.queryForm.orgId="";
          }
          this.getDataRangByNature();
        }
      })
    },
    //获取项目部
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature({orgId:this.queryForm.orgId}).then(res => {
        if (res.code === 200) {
          this.projectList=[];
          this.queryForm.ids=[]
          let { data } = res;
          if(data.length){
            data.unshift({orgName: '全选', orgId: '' });
            this.projectList.push(...data);
            //这里还要做件事情，把orgId放在一个数组里面
            this.projectList.forEach(item=>{
              if(item.orgId!==''){
                this.queryForm.ids.push(item.orgId);
              }
            });
          }
          //调取图表接口
           this.getRickPointPhotoData();
        }
      });
    }
  },
  created() {
    this.configDetailList(true);
    this.getDataRang();
  }
};
</script>

<style scoped lang="less"></style>
