<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="模糊查询">
          <el-input v-model="queryForm.search" placeholder="请输入风险点/位置" style="width:280px"></el-input>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.ids" placeholder="请选择所属项目部" multiple collapse-tags>
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其它筛选条件">
          <el-select v-model="queryForm.accidentTypeIds" placeholder="请选择主要事故类型" multiple collapse-tags>
            <el-option v-for="item in accidentTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.riskLevelIds" placeholder="请选择风险级别" multiple collapse-tags>
            <el-option v-for="item in riskLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <operate-button>
      <template #default>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="addRiskPoint"
          v-if="$check('risk-control_add')"
          >新增风险点</el-button
        >
        <el-button
          v-if="$check('risk-control_export')"
          class="export-btn"
          @click="handleExport"
          :disabled="btnDisabeld"
          v-loading.fullscreen="exportLoading"
          element-loading-text="拼命导出中"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          >导出</el-button
        >
      </template>
    </operate-button>
    <!-- 列表 -->
    <div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoding"
        ref="multipleTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center" type="index"> </el-table-column>
        <el-table-column
          prop="projectDepartmentName"
          label="所属项目部"
          fixed
          align="center"
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column prop="riskPoint" label="风险点" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="location" label="位置" align="center" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="accidentTypeName" label="主要事故类型" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="riskLevelName" label="风险级别" align="center" width="100" sortable> </el-table-column>
        <el-table-column prop="controlMeasures" label="主要管控措施" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="responsibleUnit" label="责任单位" align="center" show-overflow-tooltip sortable>
        </el-table-column>
        <el-table-column prop="responsible" label="责任人" align="center" width="100" sortable> </el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="viewDetail(row)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- dialog -->
    <list-display-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :project-list="projectList"
      :accident-type-list="accidentTypeList"
      :risk-level-list="riskLevelList"
      :echo-form-data="echoFormData"
      @handel-success="handelSuccess"
    ></list-display-dialog>
  </div>
</template>
<script>
import { exportFun } from '@/utils/export.js';
import { selectList } from '../selectList.js';
import { getDataRangByNature } from 'api/common';
import { getRickPointPage, exportRiskPoint } from 'api/general-user/prevention-control/risk-control';
import listDisplayDialog from './listDisplayDialog';
import operateButton from '../../_components/operateButton';
export default {
  components: {
    operateButton,
    listDisplayDialog
  },
  mixins: [selectList],
  data() {
    return {
      queryForm: {
        search: '',
        ids: [],
        accidentTypeIds: [],
        riskLevelIds: [],
        pageSize: 10,
        pageNo: 1
      },
      tableData: [],
      projectList: [],
      total: 0,
      tabelLoding: false,
      dialogTitle: '',
      dialogVisible: false,
      isRead: false,
      echoFormData: null,
      //导出需要的ids
      exportLoading: false,
      ids: [],
      btnDisabeld: true
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.ids.length);
    },
    //导出
    handleExport() {
      this.exportLoading = true;
      exportRiskPoint({
        ids: this.ids
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    addRiskPoint() {
      //新增风险点
      this.dialogVisible = true;
      this.dialogTitle = '新增风险';
    },
    viewDetail(row) {
      this.isRead = true;
      this.dialogVisible = true;
      const obj = JSON.parse(JSON.stringify(row)); //这里偷懒用下深拷贝，没设计到太复杂的拷贝
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
      this.dialogTitle = '查看详情';
    },
    //分页
    getRickPointPage() {
      this.tabelLoding = true;
      getRickPointPage(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tabelLoding = false;
          const { total, records } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    handelSuccess(boolean) {
      //true->新增，false编辑
      //刷新列表
      if (boolean) {
        this.queryForm.pageNo = 1;
      }
      this.getRickPointPage();
    },
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getRickPointPage();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getRickPointPage();
    },
    handelReset() {
      //重置
      this.queryForm = {};
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.$refs.multipleTable.clearSort();
      this.getRickPointPage();
    },
    handelSearch() {
      //搜索
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getRickPointPage();
    },
    getDataRangByNature() {
      //这个单独接口获取(所属项目部)
      getDataRangByNature().then(res => {
        if (res.code === 200) {
          let { data } = res;
          this.projectList = data;
        }
      });
    }
  },
  created() {
    this.getRickPointPage();
    this.configDetailList(false);
    this.getDataRangByNature();
  }
};
</script>

<style scoped lang="less">
.search-list {
  border-bottom: 1px solid #dcdfe7;
}
</style>
