<template>
  <el-dialog :title="dialogTitle" :visible.sync="show" width="850px" :close-on-click-modal="false" @close="handelClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="120px">
      <el-row>
        <el-col :span="10">
          <el-form-item label="所属项目部" :required="!isRead" prop="projectDepartment">
            <el-select v-model="ruleForm.projectDepartment" placeholder="请选择" style="width:250px" :disabled="isRead">
              <el-option
                v-for="item in projectList"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="风险点" :required="!isRead" prop="riskPoint">
            <el-input
              v-model="ruleForm.riskPoint"
              placeholder="请输入"
              style="width:250px"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="3">
          <el-form-item label="位置" :required="!isRead" prop="location">
            <el-input
              v-model="ruleForm.location"
              style="width:250px"
              placeholder="请输入"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="主要事故类型" :required="!isRead" prop="accidentTypeIds">
            <el-select
              v-model="ruleForm.accidentTypeIds"
              placeholder="请选择"
              style="width:250px"
              :disabled="isRead"
              multiple
              @change="changeAccidentTypeList"
            >
              <el-option
                v-for="item in accidentTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="3">
          <el-form-item label="风险级别" :required="!isRead" prop="riskLevelId">
            <el-select
              v-model="ruleForm.riskLevelId"
              placeholder="请选择"
              style="width:250px"
              :disabled="isRead"
              @change="changeRiskLevelList"
            >
              <el-option v-for="item in riskLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="主要管控措施" :required="!isRead" prop="controlMeasures">
            <el-input
              v-model="ruleForm.controlMeasures"
              type="textarea"
              placeholder="请输入"
              :maxlength="200"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="责任单位" :required="!isRead" prop="responsibleUnit">
            <el-input
              v-model="ruleForm.responsibleUnit"
              placeholder="请输入"
              style="width:250px"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" :offset="3">
          <el-form-item label="责任人" :required="!isRead" prop="responsible">
            <el-input
              v-model="ruleForm.responsible"
              style="width:250px"
              placeholder="请输入"
              :disabled="isRead"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="附件">
            <div v-if="isRead">
              <div
                v-for="item in ruleForm.measureFileList"
                :key="item.fileId"
                @click="uploadFile(item.fileId)"
                style="cursor:pointer"
              >
                {{ item.fileOriginName }}
              </div>
            </div>
            <upload-multiple-file
              v-else
              v-model="ruleForm.measure"
              :default-list="defaultList"
              @get-file-data="getFileData"
            ></upload-multiple-file>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" v-if="$check('risk-control_edit')">
      <span v-if="!isRead">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary">确定</el-button>
      </span>
      <span v-else>
        <el-button type="danger" @click="handelDelete">删除</el-button>
        <el-button type="primary" @click="handelUpdate">修改</el-button>
      </span>
    </div>
  </el-dialog>
</template>

<script>
import UploadMultipleFile from 'components/Upload/UploadMultipleFile';
import downloadFileFunc from '@/utils/download-file.js';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { cloneDeep } from 'lodash';
import { validateRules } from './validateRules.js';
import { addRickPoint, updateRickPoint, deleteRickPoint } from 'api/general-user/prevention-control/risk-control';
export default {
  name: 'listDisplayDialog',
  mixins: [validateRules],
  components: {
    UploadMultipleFile
  },
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
    //这些都是父组件传下来的下拉数据（这里没调取接口，感觉太臃肿了，采取的是传值）
    projectList: {
      //所属项目部
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    accidentTypeList: {
      //事故类型下拉列表
      type: Array,
      required: true,
      default: () => {
        [];
      }
    },
    riskLevelList: {
      //风险级别下拉列表
      type: Array,
      required: true,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      dialogLoading: false,
      ruleForm: {
        accidentTypeIds: [],
        accidentTypeNames: []
      },
      natureList: [],
      show: this.dialogVisible,
      //这里赋值一份表单回显数据
      ruleDeepForm: {},
      defaultList: []
    };
  },
  methods: {
    initFormDetail(row) {
      this.ruleDeepForm = cloneDeep(row);
      this.ruleForm = row; //这里对象中的id有值
      this.ruleForm.accidentTypeIds = row.accidentTypeId.split(',');
      //这里都去中文，不取id值回显（针对于系统可配置的下拉选项）
      this.ruleForm.projectDepartment = row.projectDepartmentName;
      this.ruleForm.riskLevelId = row.riskLevelName;
      if (this.ruleForm.measureFileList && this.ruleForm.measureFileList.length) {
        this.ruleForm.measureFileList.forEach(item => {
          item.name = item.fileOriginName;
          item.url = item.filePath;
        });
        this.defaultList = this.ruleForm.measureFileList;
      }
    },
    getFileData(fileIdList) {
      this.ruleForm.measure = fileIdList.join(',');
    },
    changeAccidentTypeList(id) {
      this.$forceUpdate();
      this.ruleForm.accidentTypeNames = [];
      let filterSelectList = [];
      filterSelectList = this.accidentTypeList.filter(item => {
        return id.includes(item.id); //筛选出匹配数据
      });
      if (filterSelectList && filterSelectList.length) {
        filterSelectList.forEach(item => {
          this.ruleForm.accidentTypeNames.push(item.name);
        });
      }
    },
    changeRiskLevelList(id) {
      let obj = {};
      obj = this.riskLevelList.find(item => {
        return item.id === id; //筛选出匹配数据
      });
      this.ruleForm.riskLevelName = obj.name;
    },
    submitForm() {
      //确认
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.id) {
            //id有值为编辑，否则为新增
            this.updateRickPoint();
          } else {
            this.addRickPoint();
          }
        }
      });
    },
    //新增接口
    addRickPoint() {
      addRickPoint(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', true);
        }
      });
    },
    updateRickPoint() {
      //编辑接口
      updateRickPoint(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('编辑成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    deleteRickPoint() {
      //删除接口
      deleteRickPoint({ id: this.ruleForm.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.ruleForm = {};
      this.ruleDeepForm = {};
      this.defaultList = [];
      this.$refs.ruleForm.resetFields();
    },
    handelUpdate() {
      this.dialogLoading = true;
      this.$emit('update:dialogTitle', '修改风险');
      this.$emit('update:isRead', false);
      this.ruleForm = this.ruleDeepForm;
      this.ruleForm.accidentTypeIds = this.ruleDeepForm.accidentTypeId.split(',');
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
    },
    handelDelete() {
      this.$confirm('是否确认删除该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteRickPoint();
      });
    },
    //下载附件
    uploadFile(fileId) {
      downloadFile({ fileId }).then(res => {
        downloadFileFunc(res);
      });
    }
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    }
  }
};
</script>

<style scoped lang="less">
/deep/.el-textarea__inner {
  height: 96px;
}
</style>
