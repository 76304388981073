import { configDetailList } from 'api/common';
export const selectList = {
  data() {
    return {
      projectList: [], //所属项目部
      accidentTypeList: [], //事故类型
      riskLevelList: [] //风险级别
    };
  },
  methods: {
    configDetailList(boolean) {
      //true自定义不加全选，反之
      //获取下拉配置项
      configDetailList({ module: 5 }).then(res => {
        const { five_type, five_level } = res.data;
        if (boolean) {
          if (five_type.length) {
            five_type.unshift({ name: '全选', id: '' });
          }
          if (five_level.length) {
            five_level.unshift({ name: '全选', id: '' });
          }
        }
        this.accidentTypeList = five_type;
        this.riskLevelList = five_level;
      });
    }
  }
};
