import request from '@/utils/request.js';
/**
 *获取风险点分页
 * @returns {Promise}
 */
export function getRickPointPage(data) {
  return request({
    url: '/traffic-construction/getRickPointPage',
    method: 'post',
    data
  });
}

/**
 *新增风险点
 * @returns {Promise}
 */
export function addRickPoint(data) {
  return request({
    url: '/traffic-construction/addRickPoint',
    method: 'post',
    data
  });
}

/**
 *编辑风险点
 * @returns {Promise}
 */
export function updateRickPoint(data) {
  return request({
    url: '/traffic-construction/updateRickPoint',
    method: 'post',
    data
  });
}
/**
 *删除风险点
 * @returns {Promise}
 */
export function deleteRickPoint(data) {
  return request({
    url: '/traffic-construction/deleteRickPoint',
    method: 'post',
    data
  });
}

/**
 *获取图表数据
 * @returns {Promise}
 */
export function getRickPointPhotoData(data) {
  return request({
    url: '/traffic-construction/getRickPointPhotoData',
    method: 'post',
    data
  });
}

/**
 *导出
 * @returns {Promise}
 */
export function exportRiskPoint(data) {
  return request({
    url: '/traffic-construction/exportRiskPoint',
    method: 'post',
    data,
    responseType: 'blob'
  });
}
